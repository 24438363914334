// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button-blue {
  background-color: #2196f3 !important;
}

.sa-2-popup {
  width: 400px;
  height: 280px;
  border-radius: 12px;
}
.z1400 {
  z-index: 1400;
}
.w-100 {
  width: 100%;
}
.w-90 {
  width: 90%;
}
.f-16 {
  font-size: 16px !important;
}

.ag-row .ag-cell {
  display: flex;
  align-items: center;
}

.ag-root-wrapper {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

`, "",{"version":3,"sources":["webpack://./src/css/style.css"],"names":[],"mappings":"AAAA;EACE,oCAAoC;AACtC;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,mBAAmB;AACrB;AACA;EACE,aAAa;AACf;AACA;EACE,WAAW;AACb;AACA;EACE,UAAU;AACZ;AACA;EACE,0BAA0B;AAC5B;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,4BAA4B;EAC5B,6BAA6B;AAC/B","sourcesContent":[".button-blue {\n  background-color: #2196f3 !important;\n}\n\n.sa-2-popup {\n  width: 400px;\n  height: 280px;\n  border-radius: 12px;\n}\n.z1400 {\n  z-index: 1400;\n}\n.w-100 {\n  width: 100%;\n}\n.w-90 {\n  width: 90%;\n}\n.f-16 {\n  font-size: 16px !important;\n}\n\n.ag-row .ag-cell {\n  display: flex;\n  align-items: center;\n}\n\n.ag-root-wrapper {\n  border-top-left-radius: 10px;\n  border-top-right-radius: 10px;\n}\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
